import { FieldDataType, FilterStyleKey } from '@/FieldDefinitions/types';
import { encodeFieldTypeInId } from '@/Inventory/components/Table/encodeUtils';
import {
  FilterValueSelectProps,
  GetAutocompleteOptions,
  TInventoryFilter,
} from '@/Search/SearchFilters/FilterValueSelect.types';
import { FilterValueSelect } from '@/Search/SearchFilters/index';
import { limitFilterStyles } from '@/Search/SearchFilters/limitSearchFilterStyles';
import constants from 'javascripts/constants.js';
import React from 'react';
import {
  CustomOrDefaultResultColumnId,
  FieldType,
  ResultColumnsEditorProps,
} from '../../../types';
import { FilterFieldSelect, FilterFieldSelectProps } from './FilterFieldSelect';
import { FilterFieldsRow } from './FilterFieldsRow';
import { FilterStyleSelect } from './FilterStyleSelect';
const { SEARCH_FILTER_STYLES } = constants;

// TODO dedupe with eln entries stuff where possible
export type FilterItemProps = {
  handlers: {
    updateQueryFilter: (newFilter: {
      index: number;
      filter: TInventoryFilter;
    }) => void;
    removeQueryFilter: (filterIndex: number) => void;
    getAutocompleteOptions: GetAutocompleteOptions;
  };
  index: number;
  filter: TInventoryFilter | null;
  selectedFieldValues: CustomOrDefaultResultColumnId[];
} & Pick<ResultColumnsEditorProps, 'availableFilters'> &
  Pick<FilterFieldSelectProps, 'vaultNameMap'> &
  Pick<FilterValueSelectProps<FieldDataType>, 'id_list_fd_ids'>;

export class FilterItem extends React.Component<FilterItemProps> {
  handleFieldChange = (
    field_select_value: CustomOrDefaultResultColumnId,
    field_type: FieldType,
  ) => {
    const {
      index,
      availableFilters: inventoryFieldDefinitions,
      handlers,
    } = this.props;
    const {
      data_type_name,
      id,
      name: field_name,
    } = inventoryFieldDefinitions.byFieldThenSelectValue[field_type][
      field_select_value
    ];
    const filter: TInventoryFilter = {
      field_definition_id: id,
      field_name,
      data_type_name,
      field_type,
      field_style: '',
      field_values: [],
      field_select_value,
      filter_style_key: FilterStyleKey.ANY,
    };
    handlers.updateQueryFilter({ index, filter });
  };

  handleStyleChange = (filter_style_key) => {
    const { index, filter, handlers } = this.props;
    handlers.updateQueryFilter({
      index,
      filter: {
        ...filter,
        filter_style_key,
        field_values: [],
      },
    });
  };

  handleValueChange = (field_values) => {
    const { index, filter, handlers } = this.props;

    handlers.updateQueryFilter({
      index,
      filter: {
        ...filter,
        field_values,
      },
    });
  };

  handleRemove = () => {
    const { index, handlers } = this.props;
    handlers.removeQueryFilter(index);
  };

  renderFilterField = () => {
    const {
      availableFilters: inventoryFieldDefinitions,
      filter,
      selectedFieldValues,
    } = this.props;
    const selectedValue =
      filter === null
        ? ''
        : encodeFieldTypeInId({
          field_type: filter.field_type,
          id:
              filter.field_definition_id ??
              (filter.field_select_value as CustomOrDefaultResultColumnId),
        });
    return (
      <FilterFieldSelect
        selectedValue={selectedValue}
        className={'filter__field__select'}
        availableFilters={inventoryFieldDefinitions}
        selectedFieldValues={selectedFieldValues}
        handleFieldChange={this.handleFieldChange}
        multiple={false}
        vaultNameMap={this.props.vaultNameMap}
      />
    );
  };

  renderFilterStyle = () => {
    const { filter } = this.props;
    if (!filter) {
      return null;
    }
    const { field_name, data_type_name } = filter;
    if (!field_name) {
      return null;
    }

    const { filter_style_key } = filter;
    const applicableFilterStyles = limitFilterStyles(data_type_name);

    return (
      <FilterStyleSelect
        filter_style_key={filter_style_key}
        handleStyleChange={this.handleStyleChange}
        applicableFilterStyles={applicableFilterStyles}
      />
    );
  };

  renderFilterValue = () => {
    const { availableFilters: inventoryFieldDefinitions, filter } = this.props;
    if (!filter || !inventoryFieldDefinitions) {
      return null;
    }
    const { filter_style_key, field_select_value, field_type } = filter;
    if (
      SEARCH_FILTER_STYLES.filter((filterStyle) => filterStyle.get('general'))
        .map((filterStyle) => filterStyle.get('key'))
        .includes(filter_style_key)
    ) {
      return null;
    }
    return (
      <FilterValueSelect
        fieldDefinition={
          inventoryFieldDefinitions.byFieldThenSelectValue[field_type][
            field_select_value
          ]
        }
        filter={filter}
        handleValueChange={this.handleValueChange}
        id_list_fd_ids={this.props.id_list_fd_ids}
        {...this.props.handlers}
      />
    );
  };

  render() {
    return (
      <FilterFieldsRow
        FilterField={this.renderFilterField}
        FilterStyle={this.renderFilterStyle}
        FilterValue={this.renderFilterValue}
        isRemovable={true}
        onRemoveClick={this.handleRemove}
      />
    );
  }
}
