/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import React from 'react';

import { DDFormProps, RenderElementFunction } from '@/shared/components/DDForm/DDForm';
import { ElementDef, FieldValueType, TreeDef } from '@/shared/components/DDForm/types';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { TreeItem, TreeView } from '@mui/lab';
import { TreeItemDef } from '../../types/treeDef';
import { getElementAttributes, renderElement } from '../muiRenderers';

export const ddMuiRenderTree: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  const treeDef = element as TreeDef;

  const TreeItemWithChildren = (itemProps: { item: TreeItemDef; }) => {
    const { item } = itemProps;

    const contents = item.itemField
      ? renderElement(props, item.itemField, getValue, setValue, formErrors)
      : item.label;

    return (
      <TreeItem key={item.key} nodeId={item.key} label={contents}>
        {item.children?.map((child) => {
          const childContents = child.itemField
            ? renderElement(
              props,
              child.itemField,
              getValue,
              setValue,
              formErrors,
            )
            : child.label;

          return (
            <TreeItem
              className={`element-tree-item ${child.className}`}
              key={child.key}
              nodeId={child.key}
              label={childContents}
            />
          );
        })}
      </TreeItem>
    );
  };

  return (
    <TreeView
      key={element.renderKey}
      {...getElementAttributes(element, props)}
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      expanded={treeDef.expanded}
    >
      {treeDef.children?.map((item) => (
        <TreeItemWithChildren key={item.key} item={item} />
      ))}
    </TreeView>
  );
};
