import React from 'react';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { IconButton } from '@mui/material';
import { HasIdAndName } from '@/Accounts/types';

type Props = {
  group: HasIdAndName;
  showExpandButton: boolean;
  expanded: boolean;
  onChangeExpanded(group: HasIdAndName, val: boolean);
  renderAdditionalElements?: () => React.ReactNode; // to the right of the group name
  renderAdditionalHeaderRow?: () => React.ReactNode; // below the top header row
};

function DropdownIndicator(props: { menuIsOpen: boolean }) {
  return (
    <KeyboardArrowDown
      className={props.menuIsOpen ? 'dropdown dropdownOpen' : 'dropdown dropdownClosed'}
    />
  );
}

export default class StickyDataTableHeader extends React.Component<Props> {
  handleToggleExpanded = () => {
    this.props.onChangeExpanded(this.props.group, !this.props.expanded);
  };

  render() {
    return <div className='sticky-header-container'>
      <div className='sticky-header'>
        <span className='label-text right-margin'>
          <IconButton onClick={this.handleToggleExpanded}>
            <DropdownIndicator menuIsOpen={this.props.expanded} />
          </IconButton>
          {this.props.group.name}
        </span>

        {this.props.renderAdditionalElements?.()}
      </div>

      <div className='sticky-header-additional-row'>
        {this.props.renderAdditionalHeaderRow?.()}
      </div>
    </div>;
  }
}
