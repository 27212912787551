import { List, Map, Record } from 'immutable'

const DEFAULTS = {
  id: null,
  title: null,
  identifier: null,
  name: null,
  fieldsByDefId: [],
  user_id: null,
  user_full_name: '',
  submitted_date: '',
  finalized_date: '',
  eln_witness_id: '',
  eln_witness_name: '',
  project_set_id: null,
  project_id: null,
  data_set_id: null,
  status: 'open',
  body: null,
  attached_file_associations_count: 0,
  updated_at: '',
  created_at: '',
  isPrinting: false,
}

export class Entry extends new Record(DEFAULTS) {
  static initialState(entry = { eln_fields: [] }) {
    const { eln_fields } = entry

    return new Entry(Object.assign({}, entry, {
      fieldsByDefId: this.collectByDefIds(eln_fields),
    }))
  }

  static fromJSON(properties = {}) {
    let { body, eln_fields, 'printing?': isPrinting } = properties

    body = JSON.parse(body || null)

    return new Entry(Object.assign({}, properties, {
      body,
      fieldsByDefId: this.collectByDefIds(eln_fields),
      isPrinting,
    }))
  }

  static collectByDefIds(eln_fields = []) {
    return eln_fields.reduce((memo, elnField) => {
      return memo.set(elnField.eln_field_definition_id, elnField)
    }, Map())
  }
}

const COLLECTION = {
  byId: Map(),
  all: List(),
}

export class Entries extends new Record(COLLECTION) {
  static initialState(entries) {
    return new Entries().append(entries)
  }

  append(entries = []) {
    let { byId, all } = this

    entries.forEach(entry => {
      byId = byId.set(entry.id, Entry.initialState(entry))
      all = all.push(entry.id)
    })

    return new Entries({ byId, all })
  }
}
