import { FieldDataType, FieldPickValue } from '@/FieldDefinitions/types';
import { PickListItemsEditor } from '@/components/PickListItemsEditor/PickListItemsEditor';
import { CDDModalForm } from '@/shared/components/CDDForm/CDDForm';
import { CDDElements, deleteButtonLayout } from '@/shared/components/CDDForm/cddElements';
import { DDFormProps } from '@/shared/components/DDForm/DDForm';
import { layoutBuilder } from '@/shared/components/DDForm/layoutBuilder';
import { ElementType, FieldValueType } from '@/shared/components/DDForm/types';
import { CustomFieldDef } from '@/shared/components/DDForm/types/customFieldDef';
import { SelectSingleDef } from '@/shared/components/DDForm/types/selectSingleDef';
import { Img } from '@/shared/components/sanitizedTags.js';
import { term } from '@/shared/utils/stringUtils';
import { Dialog, DialogContent, DialogTitle, Icon, Tooltip } from '@mui/material';
import HelpIcon from 'ASSETS/images/cdd30/icons/help.png';
import AddIcon from 'ASSETS/images/cdd30/icons/add.png';
import { unescape } from 'lodash';
import { computed, makeObservable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { ReadoutDefinitionUtils } from '../readoutDefinitionUtils';
import { EditProtocolReadoutDefinitionsStore } from '../stores/editProtocolReadoutDefinitionsStore';
import { FormulaEditorLegacyWrapper } from './FormulaEditorLegacyWrapper';
import { ReadoutDefinitionObject } from '../types';
import { A } from '@/shared/components/sanitizedTags';
import { getElementAttributes } from '@/shared/components/DDForm/mui/muiRenderers';

const {
  row,
  column,
  textInput,
  numberInput,
  select,
  typography,
  checkbox,
  button,
  radioGroup,
  custom,
} = layoutBuilder;

type Props = {
  store: EditProtocolReadoutDefinitionsStore;
};

@observer
export class EditProtocolReadoutDefinitionDialog extends React.Component<Props> {
  readonly selectDisplayFormat = (key: string) => select({
    label: 'Display Format',
    className: 'narrow-control select-display-format',
    id: 'precision_readout_definition',
    width: 'auto',
    key,
    selectOptions: [
      { label: 'None', id: 'None' },
      {
        label: 'Decimal places',
        id: 'Decimal places',
        children: [
          { label: '0 (integer)', id: '0 (integer)' },
          { label: '1 decimal place', id: '1 decimal place' },
          ...[2, 3, 4, 5, 6].map((i) => ({ label: `${i} decimal places`, id: `${i} decimal places` })),
        ],
      },
      {
        label: 'Significant figures',
        id: 'Significant figures',
        children: [
          { label: '1 significant figure', id: '1 significant figure' },
          ...[2, 3, 4, 5, 6].map((i) => ({ label: `${i} significant figures`, id: `${i} significant figures` })),
        ],
      },
    ],
  });

  get readoutType() {
    const { value } = this.props.store;
    if (this.isCreatingNew) {
      const data_type_name = value.readout_definition_object?.data_type_name as string;
      switch (data_type_name) {
        case 'dose-response':
        case 'calculated':
          return data_type_name;

        default:
          return 'readout';
      }
    }
    return ReadoutDefinitionUtils.getType(value);
  }

  readonly tooltipProps: Partial<React.ComponentProps<typeof Tooltip>> = {
    componentsProps: {
      tooltip: {
        sx: {
          bgcolor: 'rgb(252, 252, 252)',
          color: 'rgb(89, 50, 22)',
          minWidth: '40rem',
          padding: '1.5rem',
          border: '1px solid rgba(89, 50, 22, .5)',
          fontSize: '1rem',
        },
      },
    },
  };

  constructor(props: Props) {
    super(props);
    makeObservable(this, {
      layout: computed,
      layoutForNormalization: computed,
    });
  }

  get valueBeforeEdits() {
    return this.props.store.valueBeforeEdits;
  }

  handleClose = (_: unknown, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'escapeKeyDown') {
      this.props.store.handleCancelEdit();
    }
  };

  handleOK = () => {
    const aggregation: HTMLSelectElement = document.getElementById('custom_calculation_aggregate_readouts_by') as HTMLSelectElement;
    if (aggregation) {
      runInAction(() => {
        this.props.store.value.custom_calculation_object.aggregate_readouts_by = aggregation.value;
      });
    }

    this.props.store.handleSubmit(this.isCreatingNew ? this.readoutType : undefined);
  };

  handleDelete = () => {
    this.props.store.handleDelete();
  };

  get value() {
    return this.props.store.value;
  }

  get layout() {
    const { readoutType } = this;
    const rows: ElementType[] = [];

    switch (readoutType) {
      case 'readout':
        rows.push(...this.layoutForReadoutDefinition);
        break;
      case 'dose-response':
        rows.push(...this.layoutForDoseResponse);
        break;
      case 'calculated':
        rows.push(...this.layoutForCalculated);
        break;
    }

    return column(rows);
  }

  private get layoutSelectDataType() {
    let disabled = false;
    let disabledMessage: string;

    if (this.isEditingExisting && this.value.readout_definition_object?.disabled) {
      disabledMessage = `Data type cannot be changed if there are ${term('readout.other')}. Contact support if you need help.`;
      disabled = true;
    }
    const selectOptions: SelectSingleDef['selectOptions'] = [];
    if (this.isCreatingNew || this.readoutType === 'readout') {
      selectOptions.push(
        { label: 'Number', id: FieldDataType.Number },
        { label: 'Text', id: FieldDataType.Text },
        { label: 'Pick List', id: FieldDataType.PickList },
        { label: 'File', id: FieldDataType.File },
        { label: `${term('batch', true)} Link`, id: FieldDataType.BatchLink },
      );
    }
    if (this.isCreatingNew || this.readoutType === 'calculated') {
      selectOptions.push({ label: 'Calculated', id: 'calculated' });
    }
    if (this.isCreatingNew || this.readoutType === 'dose-response') {
      selectOptions.push({ label: 'Plot', id: 'dose-response' });
    }

    if (this.isEditingExisting && (this.readoutType === 'calculated' || this.readoutType === 'dose-response')) {
      disabled = true;
    }
    return select({
      label: 'Data Type',
      key: 'readout_definition_object.data_type_name',
      id: 'data_type_name_readout_definition',
      width: 'auto',
      autoFocus: !this.value.readout_definition_object?.data_type_name,
      className: 'data-type-select',
      passIdOnSelect: true,
      required: true,
      selectOptions,
      disabled,
      tooltip: disabledMessage,
      translateGetValue: (id) => {
        if (selectOptions.length === 1) {
          return selectOptions[0].id;
        }
        return this.value.readout_definition_object?.data_type_name;
      },
    });
  }

  get layoutForNormalization() {
    const { mapHelpToContent } = this.props.store;
    const { readoutType, value, value: { readout_definition_object, custom_calculation_object } } = this;

    if (!value) {
      return;
    }
    let normalization: string;
    let normalizationVisible = false;
    let normalizationDisabled = custom_calculation_object?.curve_analytics_enabled === false;
    let availableNormalizedCalculations = [];

    let keyPrefix: string;

    switch (readoutType) {
      case 'readout':
        normalization = readout_definition_object.normalization_scope ?? '';
        normalizationVisible = (readout_definition_object?.data_type_name === FieldDataType.Number) &&
          !readout_definition_object.protocol_condition;
        normalizationDisabled = !normalizationVisible;
        availableNormalizedCalculations = readout_definition_object.available_normalized_calculations ?? [];
        keyPrefix = 'readout_definition_object';
        break;

      case 'calculated':
        normalization = custom_calculation_object.output_readout_definition_attributes.normalization_scope ?? '';
        availableNormalizedCalculations = custom_calculation_object.output_readout_definition_attributes.available_normalized_calculations ?? [];
        normalizationVisible = true;
        keyPrefix = 'custom_calculation_object.output_readout_definition_attributes';
        break;

      default:
        return;
    }

    const normalizationIdPrefixes = [
      'readout_definition_percent_inhibition_calculation',
      'readout_definition_positive_percent_of_control_calculation',
      'readout_definition_negative_percent_of_control_calculation',
      'readout_definition_z_score_calculation',
    ].map(prefix => (readoutType === 'calculated') ? prefix.replace('readout_definition', 'custom_calculation') : prefix);

    return column({
      legend: 'Normalization',
      visible: normalizationVisible,
    },
    [
      typography({
        visible: (readoutType === 'calculated') && custom_calculation_object?.curve_analytics_enabled === false,
        label: 'Curves must be enabled to normalize calculated values.',
      }),
      radioGroup({
        key: `${keyPrefix}.normalization_scope`,
        id: 'normalization_scope',
        horizontal: true,
        selectOptions: [
          { id: '', label: `Do not normalize this ${term('readout')}` },
          { id: 'plate', label: `Normalize within each ${term('plate')}` },
          { id: 'run', label: `Normalize within each ${term('run')}` },
        ],
        disabled: normalizationDisabled,
      }),
      column({
        visible: !!normalization,
        id: 'readout_definition-normalizations-options',
        className: 'readout-definition-normalizations-options',
      }, [
        row({ spacing: 0 }, [
          checkbox({
            className: 'subtract-normalized-checkbox normalizations-menu-checkbox-width',
            id: 'invert_normalized_value_placeholder',
            key: `${keyPrefix}.invert_normalized_value_selected`,
            label: 'Subtract normalized value from 100% (e.g. for % Growth)',
            disabled: normalizationDisabled,
            width: 'expand',
          }),
          typography({ label: 'Excludes z score normalization', className: 'description z-score-normalization-description' }),
        ]),

        ...(availableNormalizedCalculations).map((calculation, index) => {
          const selectedPath = `${keyPrefix}.available_normalized_calculations[${index}].selected`;
          return column({ controlAttributes: { 'data-description': calculation.description } }, [
            row({ spacing: 0, noPadding: true, className: 'normalization-row' }, [
              row({ spacing: 0, className: 'editable-normalization-checkbox-row normalizations-menu-checkbox-width' }, [
                checkbox({
                  className: 'normalizations-menu-checkbox',
                  key: selectedPath,
                  id: `${normalizationIdPrefixes[index]}_checkbox`,
                  width: '1.6rem',
                  disabled: normalizationDisabled,
                  label: calculation.selected ? '' : (calculation.name || calculation.label),
                }),
                textInput({
                  visible: calculation.selected,
                  key: `${keyPrefix}.available_normalized_calculations[${index}].name`,
                  id: `${normalizationIdPrefixes[index]}_input`,
                  disabled: normalizationDisabled,
                }),
              ]),
              typography({
                label: calculation.description,
                className: 'normalization-description',
              }),
              typography({
                className: 'protocol_conditions-help-button help-icon',
                label: <><Icon className='help-icon'><Img src={HelpIcon} /></Icon></>,
                width: '1rem',
                tooltip: <div dangerouslySetInnerHTML={{ __html: mapHelpToContent[calculation.label] }} />,
                tooltipProps: this.tooltipProps,
              }),
            ]),

            ...(calculation.selected && calculation.mean_type_options?.length > 0)
              ? [row({
                  className: 'mean-type-row',
                  spacing: 0,
                  noPadding: true,
                }, [
                  radioGroup({
                    className: 'inset',
                    label: 'Mean type: ',
                    key: `${keyPrefix}.available_normalized_calculations[${index}].mean_type`,
                    horizontal: true,
                    translateGetValue: (id) => {
                      return id || calculation.mean_type_options[0]?.[1];
                    },
                    selectOptions: (calculation.mean_type_options ?? []).map(options => ({ id: options[1], label: options[0] })),
                    disabled: normalizationDisabled,
                  }),
                ]),
                ]
              : [],
          ]);
        },
        ),
        typography({
          visible: (normalization === ''),
          label: 'Normalization makes it easier to understand and compare data, which is essential for a collaborative database. It is recommended that you select a normalization option above (unless your data is already normalized).',
        }),
      ]),
    ]);
  }

  get layoutForReadoutDefinition() {
    const { value: { readout_definition_object }, mapHelpToContent, serverValidationErrors } = this.props.store;
    const rows: ElementType[] = [];

    const normalization = readout_definition_object.normalization_scope ?? '';
    const dataTypeName = readout_definition_object?.data_type_name;
    const protocolConditionVisible =
      // must have a data type selected
      !!dataTypeName &&
      // but not File
      (dataTypeName !== FieldDataType.File) &&
      // and there shouldn't be normalization (or normalization is hidden)
      (!normalization || dataTypeName !== 'Number');

    // perhaps should be a reaction, a little hacky, but: if the Protocol Condition checkbox isn't visible
    // and the value is checked, uncheck it.
    if (readout_definition_object.protocol_condition && !protocolConditionVisible) {
      setTimeout(() => {
        runInAction(() => {
          readout_definition_object.protocol_condition = false;
        });
      });
    }

    rows.push(column({ noPadding: true }, [
      row({ className: 'data-type-row' }, [
        this.layoutSelectDataType,
        row({
          width: 0,
          spacing: 0,
          noPadding: true,
          // If we use the visible parameter, then this row is only rendered when picklist is selected, and we won't
          // open the picklist editor when the user selects picklist.  So we use the className parameter instead to hide it.

          visible: (readout_definition_object?.data_type_name === FieldDataType.PickList),
          renderHidden: true,
        }, [
          custom({
            key: 'readout_definition_object.pick_list_values',
            width: '0',
            render: (field: CustomFieldDef,
              _props: DDFormProps,
              _element: CustomFieldDef,
              getValue: (field: CustomFieldDef) => FieldPickValue[],
              setValue: (field: CustomFieldDef, value: FieldValueType) => void) =>
              <div className='picklist-field'>
                <PickListItemsEditor
                  dialogId='new_readout_definitionPickListDialog'
                  disabled={false}
                  dataType={(readout_definition_object?.data_type_name ?? '') as FieldDataType}
                  values={(getValue(field) as FieldPickValue[]) ?? []}
                  onChange={value => setValue(field, value)}
                />
              </div>,
          }),
        ]),
        textInput({
          key: 'readout_definition_object.name',
          className: 'readout-definition-name',
          id: 'name_readout_definition',
          label: 'Name:',
          disabled: false,
          required: true,
          autoFocus: true,
          error: serverValidationErrors?.name?.join(''),
          autocomplete: false,
          translateSetValue: (value: string) => {
            this.props.store.updateReadoutNormalizationNames(readout_definition_object.name, value);
            return value === ' ' ? '' : value;
          },
        }),
        checkbox({
          className: 'lower-checkbox',
          label: 'Required',
          key: 'readout_definition_object.required',
          width: '8rem',
        }),
        row({ visible: protocolConditionVisible }, [
          checkbox({
            key: 'readout_definition_object.protocol_condition',
            id: 'readout_definition_protocol_condition',
            className: 'lower-checkbox',
            label: `${term('protocol', true)} Condition`,
            width: '11rem',
          }),
          typography({
            id: 'protocol_conditions-help-button',
            className: 'protocol_conditions-help-button help-icon',
            label: <><Icon><Img src={HelpIcon} /></Icon></>,
            width: '1rem',
            tooltip: <div dangerouslySetInnerHTML={{ __html: mapHelpToContent.protocol_conditions }} />,
            tooltipProps: this.tooltipProps,
          }),
        ]),
      ]),

      typography({
        className: 'pickListSummaryInformation buttony-description-small',
        visible: (readout_definition_object?.data_type_name === FieldDataType.PickList),
        label: ReadoutDefinitionUtils.getPickListSummary(readout_definition_object),
      }),
    ]));

    rows.push(
      row({
        visible: (readout_definition_object?.data_type_name === FieldDataType.Number),
      }, [
        textInput({
          key: 'readout_definition_object.unit_label',
          id: 'unit_label_readout_definition',
          label: 'Unit',
          className: 'narrow-control',
        }),
        this.selectDisplayFormat('readout_definition_object.display_format'),
      ]),

      row([
        textInput({
          label: 'Description',
          id: 'description_readout_definition',
          key: 'readout_definition_object.description',
        }),
      ]),

      this.layoutForNormalization,
    );
    return rows;
  }

  get layoutForDoseResponse() {
    const rows: ElementType[] = [];
    const {
      store,
      store: { value: { dose_response_calculation_object }, calculationOptions },
    } = this.props;

    if (!dose_response_calculation_object) {
      return [];
    }

    const getNameAndUnitLabel = (row: ReadoutDefinitionObject) => {
      return row.format_unit_label ? `${row.name} (${row.unit_label})` : row.name;
    };
    const xReadoutDefinitionsOptions = store.protocolReadoutDefinitionsStore.rows.filter(row => {
      return row.usable_as_dose_in_dose_response_calculations;
    }).map(row => ({ id: row.id, label: getNameAndUnitLabel(row) }));

    const yReadoutDefinitionsOptions = store.protocolReadoutDefinitionsStore.rows.filter(row => {
      return row.usable_as_response_in_dose_response_calculations;
    }).map(row => ({ id: row.id, label: getNameAndUnitLabel(row) }));

    // so that both x & y selects are the same widths:
    xReadoutDefinitionsOptions.push(...yReadoutDefinitionsOptions.map(option => ({ ...option, measurementOnly: true })));
    yReadoutDefinitionsOptions.push(...xReadoutDefinitionsOptions.map(option => ({ ...option, measurementOnly: true })));

    const yReadoutDefinitionUnit = store.protocolReadoutDefinitionsStore.rows.filter(row => {
      return row.id == dose_response_calculation_object.response_readout_definition_id;
    }).map(row => row.format_unit_label ? ` (${row.unit_label})` : '');

    const getAxisError = (axis: 'x' | 'y') => {
      const id = dose_response_calculation_object[(axis === 'x') ? 'dose_readout_definition_id' : 'response_readout_definition_id'];
      if (!id) {
        return `please select a valid ${term('readout_definition')}`;
      }
      if (dose_response_calculation_object.dose_readout_definition_id === dose_response_calculation_object.response_readout_definition_id) {
        return 'X and Y must be distinct';
      }
    };

    let index = 0;
    const intercept_readout_definitions_attributes =
      (dose_response_calculation_object.intercept_readout_definitions_attributes ?? [])
        .map((intercept_readout_definition) => {
          if (!intercept_readout_definition._destroy) {
            ++index;
          }
          return {
            ...intercept_readout_definition,
            index,
          };
        });

    const graph_lower_bound = dose_response_calculation_object.response_readout_definition_attributes?.graph_lower_bound ?? -Infinity;
    const graph_upper_bound = dose_response_calculation_object.response_readout_definition_attributes?.graph_upper_bound ?? Infinity;

    rows.push(row([
      this.layoutSelectDataType,

      column({ className: 'dose-response-axes' },
        [
          // scott: the following hidden field is used to include dose_readout_definition_id in the observer (wish I had thought to comment why this was needed)
          typography({ visible: false, label: `id: ${dose_response_calculation_object.dose_readout_definition_id}` }),
          typography({ visible: false, label: `id: ${dose_response_calculation_object.response_readout_definition_id}` }),

          // X axis
          row({}, [
            select({
              key: 'dose_response_calculation_object.dose_readout_definition_id',
              id: 'dose_response_calculation_dose_readout_definition_id',
              label: 'X',
              className: 'dose-response-select-axis',
              valueType: 'number',
              selectOptions: xReadoutDefinitionsOptions,
              error: getAxisError('x'),
              disabled: this.isEditingExisting,
              required: true,
            }),
          ]),

          // Y axis
          row({}, [
            select({
              key: 'dose_response_calculation_object.response_readout_definition_id',
              id: 'dose_response_calculation_response_readout_definition_id',
              label: 'Y',
              className: 'dose-response-select-axis',
              valueType: 'number',
              selectOptions: yReadoutDefinitionsOptions,
              error: getAxisError('y'),
              disabled: this.isEditingExisting,
              required: true,
            }),
            numberInput({
              key: 'dose_response_calculation_object.response_readout_definition_attributes.graph_lower_bound',
              className: 'dose-response-graph-bound',
              placeholder: 'min',
              error: (graph_lower_bound > graph_upper_bound) ? 'min > max' : undefined,
            }),
            typography({ label: 'to', width: '2rem', className: 'text__center' }),
            numberInput({
              key: 'dose_response_calculation_object.response_readout_definition_attributes.graph_upper_bound',
              className: 'dose-response-graph-bound',
              placeholder: 'max',
            }),
          ]),
        ]),

      typography({
        className: 'protocol_conditions-help-button help-icon',
        label: <><Icon><Img src={HelpIcon} /></Icon></>,
        width: '1rem',
        tooltipProps: this.tooltipProps,
        tooltip: <div>
          <h2>Quality Control</h2>
          <p>CDD provides an extensive toolkit to help you analyze your dose-response data.</p>
          <A href="https://support.collaborativedrug.com/hc/en-us/sections/202798623">Learn more...</A>
        </div>,
      }),
    ]),
    );

    const { minimum_inactivity_modifier } = dose_response_calculation_object;
    const constraints = dose_response_calculation_object.has_fit_parameter_constraints
      ? dose_response_calculation_object.equation_types_enabled.find((x) => (x.equation_type == dose_response_calculation_object.equation_type)).constrainable_fit_parameters
      : [];

    // break up constraints into two columns if > 3 elements
    const columnarConstraints = constraints.length > 3
      ? [constraints.slice(0, Math.round(constraints.length / 2)), constraints.slice(Math.round(constraints.length / 2), constraints.length)]
      : [constraints];

    rows.push(
      column({ legend: 'Fit Parameters' }, [
        row({ className: 'standard-row-height' }, [
          select({
            label: 'Curve Fit Algorithm',
            key: 'dose_response_calculation_object.equation_type',
            id: 'dose_response_calculation_object_equation_type',
            className: 'dose-response-curve-fit-algorithm',
            width: 'auto',
            disabled: this.isEditingExisting,
            selectOptions: dose_response_calculation_object.equation_types_enabled.map(
              x => ({ 'label': x.display_name, 'id': x.equation_type }),
            ),
            translateGetValue: (id) => id || '0',
          }),
          radioGroup({
            key: 'dose_response_calculation_object.has_fit_parameter_constraints',
            className: 'constrain-radio-group',
            valueType: 'boolean',
            horizontal: true,
            selectOptions: [
              { id: 'false', label: 'Do not constrain values (use best fit)' },
              { id: 'true', label: 'Constrain values' },
            ],
          }),
        ]),

        row({ className: 'constraints-row' },
          columnarConstraints.map(constraints => column(
            constraints.map(({ label, key }) => {
              const fitType = dose_response_calculation_object.constraints[`${key}_fit_type`];
              return row({ className: 'dose-response-constraint-row' }, [
                typography({ label, minWidth: '5rem' }),
                select({
                  key: `dose_response_calculation_object.constraints[${key}_fit_type]`,
                  id: `dose_response_calculation_constraints_${key}_fit_type`,
                  selectOptions: [
                    { label: '', id: '' },
                    { label: '=', id: '=' },
                    { label: 'from', id: 'from' },
                    { label: '≤', id: '<=' },
                    { label: '≥', id: '>=' },
                  ],
                }),
                numberInput({
                  key: `dose_response_calculation_object.constraints[${key}_lower_value]`,
                  id: `dose_response_calculation_constraints_${key}_lower_value`,
                  visible: !!fitType,
                  required: true,
                }),
                typography({
                  label: 'to',
                  className: 'label-to',
                  visible: (fitType === 'from'),
                }),
                numberInput({
                  key: `dose_response_calculation_object.constraints[${key}_upper_value]`,
                  id: `dose_response_calculation_constraints_${key}_upper_value`,
                  visible: (fitType === 'from'),
                  required: true,
                }),
              ]);
            }),
          ),
          )),
      ]),

      column({ legend: 'Inactive Range' }, [
        typography({
          label: <>At least one point must be outside of this range or the result will be reported as <i>&gt; maximum concentration tested</i>.</>,
        }),

        radioGroup({
          horizontal: true,
          key: 'dose_response_calculation_object.has_custom_minimum_activity',
          valueType: 'boolean',
          selectOptions: [
            {
              id: false,
              label: '3 Standard Deviations from the negative control mean (Y must be normalized)',
            },
            { id: true, label: 'Custom' },
          ],
        }),
        row({ className: 'dose-response-constraint-row', visible: dose_response_calculation_object.has_custom_minimum_activity },
          [
            typography({ label: 'Minimum activity threshold:', className: 'dsr-calc-label' }),
            select({
              key: 'dose_response_calculation_object[minimum_inactivity_modifier]',
              id: 'dose_response_calculation_minimum_inactivity_modifier',
              selectOptions:
                dose_response_calculation_object.minimum_activity_modifiers?.map(modifier => ({ label: modifier, id: modifier })) ?? [],
            }),
            numberInput({
              key: 'dose_response_calculation_object[inactivity_lower_bound]',
              id: 'dose_response_calculation_inactivity_lower_bound',
              required: true,
              visible: ['>', 'from'].includes(minimum_inactivity_modifier),
            }),
            typography({
              label: 'to',
              className: 'label-to',
              visible: minimum_inactivity_modifier === 'from',
            }),
            numberInput({
              key: 'dose_response_calculation_object[inactivity_upper_bound]',
              id: 'dose_response_calculation_inactivity_upper_bound',
              required: true,
              visible: ['<', 'from'].includes(minimum_inactivity_modifier),
            }),
            typography({ label: '(%)' }),
          ]),
      ]),

      row({
        legend: 'Data Calculations',
        className: 'data-calculations',
        // One day when we have more than one equation_type that does not have
        // an intercept, we should update this to something more elegant.
        visible: dose_response_calculation_object.equation_type != 1,
      }, [
        column([
          ...(intercept_readout_definitions_attributes)
            .map((intercept_readout_definition, index) =>
              row({
                className: 'data-calculation-row',
                id: `data-calculation-row-${intercept_readout_definition.index}`,
                visible: !intercept_readout_definition._destroy,
              }, [
                CDDElements.deleteIconButton({
                  key: index.toString(),
                  onClickButton: () => {
                    store.handleDeleteDataCalculation(index);
                  },
                }),
                select({
                  className: 'select-data-calculation',
                  key: `dose_response_calculation_object.intercept_readout_definitions_attributes[${index}].name`,
                  id: `data-calculation-row-${intercept_readout_definition.index}-select`,
                  label: intercept_readout_definition.name ? '' : 'Data calculation',
                  width: 'auto',
                  required: dose_response_calculation_object.equation_type != 1,
                  selectOptions: [
                    ...calculationOptions.map(option => ({ label: option, id: option })),
                    { label: 'Custom...', id: 'Custom...' },
                  ],
                  // eslint-disable-next-line no-nested-ternary
                  translateGetValue: (v) => v ? (calculationOptions.includes(v as string) ? v : 'Custom...') : '',
                }),

                row({ visible: (!!intercept_readout_definition.name) && !calculationOptions.includes(intercept_readout_definition.name) }, [
                  textInput({
                    label: 'Name',
                    id: `data-calculation-row-${intercept_readout_definition.index}-input-name`,
                    key: `dose_response_calculation_object.intercept_readout_definitions_attributes[${index}].name`,
                    translateGetValue: (v) => (v === 'Custom...') ? '' : v,
                  }),
                  numberInput({
                    label: 'Intersection',
                    className: 'intersection-input',
                    id: `data-calculation-row-${intercept_readout_definition.index}-input-intersection`,
                    key: `dose_response_calculation_object.intercept_readout_definitions_attributes[${index}].intercept`,
                    minValue: intercept_readout_definition.intercept_absolute ? undefined : 0,
                    maxValue: intercept_readout_definition.intercept_absolute ? undefined : 100,
                  }),
                ]),

                select({
                  visible: dose_response_calculation_object.equation_type == 2, // only for bell curve
                  required: dose_response_calculation_object.equation_type == 2, // only for bell curve
                  className: 'select-data-calculation-direction',
                  key: `dose_response_calculation_object.intercept_readout_definitions_attributes[${index}].intercept_index`,
                  id: `data-calculation-row-${intercept_readout_definition.index}-input-intercept-index`,
                  label: 'Intercept side',
                  width: 'auto',
                  selectOptions: [
                    { label: 'Left', id: '0' },
                    { label: 'Right', id: '1' },
                  ],
                }),

                select({
                  className: 'select-data-calculation-intercept-absolute',
                  key: `dose_response_calculation_object.intercept_readout_definitions_attributes[${index}].intercept_absolute`,
                  id: `data-calculation-row-${intercept_readout_definition.index}-input-intercept-absolute`,
                  label: 'Intercept',
                  width: 'auto',
                  selectOptions: [
                    { id: 'false', label: 'relative (%)' },
                    { id: 'true', label: 'absolute' + yReadoutDefinitionUnit },
                  ],
                }),
              ]),
            ),
          button({
            key: 'add_data_calculation',
            className: 'add-data-calculation-button',
            label: <><Icon className='add-icon'><Img src={AddIcon} /></Icon>Add Data Calculation</>,
            buttonType: 'link',
            onClickButton: () => store.handleAddDataCalculation(),
          }),
        ]),
        this.selectDisplayFormat('dose_response_calculation_object[calculated_display_format]'),
      ]),
    );

    return rows;
  }

  get layoutForCalculated() {
    const { value: { custom_calculation_object }, serverValidationErrors, customCalculationAutoComplete } = this.props.store;
    const rows: ElementType[] = [];

    let lingoized_aggregation_level = custom_calculation_object.lingoized_aggregation_level;
    if (lingoized_aggregation_level) {
      lingoized_aggregation_level = WebMolKit.deepClone(lingoized_aggregation_level);
      lingoized_aggregation_level.forEach(a => {
        a[0] = unescape(a[0]);
      });
    }

    rows.push(
      row([
        this.layoutSelectDataType,
        textInput({
          key: 'custom_calculation_object[output_readout_definition_attributes][name]',
          id: 'name_custom_calculation',
          label: 'Name:',
          required: true,
          error: null,
          autocomplete: false,
          translateSetValue: (value: string) => {
            this.props.store.updateReadoutNormalizationNames(custom_calculation_object.output_readout_definition_attributes.name, value);
            return value === ' ' ? '' : value;
          },
        }),
      ]),
      row([
        textInput({
          className: 'narrow-control',
          key: 'custom_calculation_object[output_readout_definition_attributes][unit_label]',
          label: 'Unit',
        }),
        this.selectDisplayFormat('custom_calculation_object[output_readout_definition_attributes][display_format]'),
      ]),
      row({ legend: 'Formula', key: 'formula_area_custom_calculation' }, [
        custom({
          key: 'custom_calculation_object.formatted_formula',
          width: 'expand',
          render: (field: CustomFieldDef,
            props: DDFormProps,
            element: CustomFieldDef,
            getValue: (field: CustomFieldDef) => FieldValueType,
            setValue: (field: CustomFieldDef, value: FieldValueType) => void) =>
            <FormulaEditorLegacyWrapper
              {...getElementAttributes(field, props)}
              value={getValue(field).toString()}
              onChange={(value: string) => {
                setValue(field, value);
                this.props.store.clearFormulaError();
              }}
              onChangeAggregation={(value: string) => {
                runInAction(() => {
                  custom_calculation_object.aggregate_readouts_by = value;
                });
              }}
              autoCompleteOptions={customCalculationAutoComplete}
              lingoizedAggregationLevel={lingoized_aggregation_level}
              aggregateReadoutsBy={custom_calculation_object.aggregate_readouts_by}
              error={serverValidationErrors?.formula ? `Formula ${serverValidationErrors?.formula.join('')}.` : null}
            />,
        }),
      ]),
      this.layoutForNormalization,
      textInput({
        label: 'Description',
        key: 'custom_calculation_object.description',
      }),
    );

    return rows;
  }

  get buttonDelete() {
    const showDelete = this.isEditingExisting && !this.isReadonly;

    return [
      deleteButtonLayout({
        visible: showDelete,
        key: 'readout_definition_object.DELETE',
        onClickButton: this.handleDelete,
      }),
    ];
  }

  get isCreatingNew() {
    return !(
      this.value?.readout_definition_object?.id ??
      this.value?.dose_response_calculation_object?.id ??
      this.value?.custom_calculation_object?.id);
  }

  get isEditingExisting() {
    return !this.isCreatingNew;
  }

  get isReadonly() {
    return false;
  }

  get dialogTitle() {
    const type = term('readout_definition', true);
    if (this.isEditingExisting) {
      const { nameOfCurrentReadout } = this.props.store;
      return `Edit ${type}: ${nameOfCurrentReadout}`;
    } else {
      return `Create ${type}`;
    }
  }

  get dialogClassName() {
    const className = 'EditProtocolReadoutDefinitionDialog';
    let type = '';
    const { value } = this.props.store;
    if (value) {
      type = value.readout_definition_object?.data_type_name;
      if (this.isEditingExisting) {
        switch (ReadoutDefinitionUtils.getType(value)) {
          case 'dose-response':
            type = 'dose-response';
            break;
          case 'calculated':
            type = 'calculated';
            break;
        }
      }
    }
    return `${className}${type ? ` ${className}-${type.toLowerCase()}` : ''}`;
  }

  render() {
    const { isOpen, value, serverValidationErrors, handleCancelEdit } = this.props.store;

    return (
      <>
        <Dialog
          open={isOpen}
          onClose={this.handleClose}
          className={this.dialogClassName}
          maxWidth={false} // Disable default max width
          fullWidth={false} // Dialog will dynamically size to content
          PaperProps={{
            style: {
              minWidth: '300px', // Set a minimum width if needed
              maxWidth: 'none', // Ensure dialog is not restricted by width
            },
          }}
        >
          {value && (
            <>
              <DialogTitle className='muiDialog-title'>
                {this.dialogTitle}

              </DialogTitle>
              <DialogContent>
                <CDDModalForm
                  data={value ?? {}}
                  layout={this.layout}
                  onOK={this.handleOK}
                  onCancel={this.isReadonly ? null : handleCancelEdit}
                  bottomLeftElements={this.buttonDelete}
                  terminology={{ OK: this.isReadonly ? 'OK' : 'Save' }}
                  showErrors={!!serverValidationErrors}
                />
              </DialogContent>
            </>
          )}
        </Dialog>
      </>
    );
  }
}
