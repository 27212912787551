import React from 'react';
import { HazardCodeIconsWithDialog } from '@cdd/ui-kit/lib/components/composite/hazardCodes/v1';
import { ThemeProvider } from '@cdd/ui-kit/lib/theme/provider';
import { theme } from '@cdd/ui-kit/lib/theme';

export const HazardCodeIconsView = (props: {
  hazard_codes: string[] | string;
  pubchem_id?: number;
  ban_external_links: boolean;
  skip_external_link_warning: boolean;
  size?: number;
}) => {
  const {
    pubchem_id,
    hazard_codes,
    ban_external_links,
    skip_external_link_warning,
    size = 50,
  } = props;

  const codes = Array.isArray(hazard_codes)
    ? hazard_codes.join(',')
    : hazard_codes;
  return (
    <ThemeProvider theme={theme}>
      <HazardCodeIconsWithDialog
        hazardCodes={codes}
        pubchemIdentifier={pubchem_id}
        shouldBanExternalLinks={ban_external_links}
        shouldSkipExternalLinkWarning={skip_external_link_warning}
        shouldDisplayAssumeToxic={false}
        size={size}
      />
    </ThemeProvider>
  );
};
