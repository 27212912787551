import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormGroup,
  FormControlLabel,
  Stack,
} from '@mui/material';

import { PropertySelection } from '../Properties/PropertySelection';
import { BioisosterSettings } from './BioisosterResultsTypes';
import { CountSelection } from '../Properties/CountSelection';
import { CollectionSelection } from '../Similarity/Collections';
import { ShouldRender } from '@cdd/ui-kit/lib/components/conditionals/ShouldRender/v1';

type BioisosterSettingsDialogProps = {
  open: boolean;
  settings: BioisosterSettings;
  hasExperimentalFeatures: boolean;
  onClose: (settings: BioisosterSettings) => void;
};

export const BioisosterSettingsDialog = (props: BioisosterSettingsDialogProps) => {
  const { open, onClose } = props;
  const [settings, setSettings] = useState<BioisosterSettings>({ ...props.settings });

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === 'Escape') {
      onClose(props.settings);
    }
  };

  const returnSelection = () => {
    onClose(settings);
  };

  return (
    <Dialog
      className='PropertySelectionDialog'
      open={open}
      onKeyDown={handleKeyDown}
      data-testid='property-selection-dialog'
    >
      <DialogTitle className='muiDialog-title'>
        Bioisostere settings
      </DialogTitle>
      <DialogContent>
        <CountSelection
          count={settings.count}
          onChange={(count) => setSettings({ ...settings, count })}
          values={[12, 18, 24]}
          label='Maximum number of hits per fragment:'
        />
        <CollectionSelection
          enabled={settings.showCollectionInformation}
          onChange={(enabledCollections) => setSettings({ ...settings, showCollectionInformation: enabledCollections })}
        />
        <PropertySelection
          selected={settings.properties}
          onChange={properties => setSettings({ ...settings, properties })}
        />
        <ShouldRender shouldRender={props.hasExperimentalFeatures} >
          <ExperimentalFeaturesSelection
            selected={settings.experimental}
            onChange={experimental => setSettings({ ...settings, experimental })}
          />
        </ShouldRender>
      </DialogContent>
      <Stack direction='row' justifyContent='space-between' alignItems='end'>
        <span />
        <DialogActions className='bottom-modal-row'>
          <Button
            variant='text'
            onClick={returnSelection}
            color='primary'
            aria-label='close dialog'
          >
            OK
          </Button>
        </DialogActions>
      </Stack>
    </Dialog >
  );
};

const ExperimentalFeaturesSelection = (props: {
  selected: boolean;
  onChange: (enabled: boolean) => void;
}) => {
  const { onChange } = props;

  const [selected, setSelected] = useState<boolean>(props.selected);
  const chkbox = <Checkbox
    sx={{ pt: '0.1em', pb: '0.1em' }}
    checked={selected}
    onChange={(e) => {
      setSelected(e.target.checked);
      onChange(e.target.checked);
    }} />;
  return (
    <Stack direction='column' alignItems='left' sx={{ pb: '12px' }}>
      <Divider orientation="horizontal" sx={{ pt: 0, pb: 0 }} />
      <FormGroup>
        <FormControlLabel control={chkbox} label='Experimental features' />
      </FormGroup>
    </Stack>
  );
};
