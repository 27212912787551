import { StandardDialogActions } from '@/components/Buttons/DialogButtons';
import { LocationValue } from '@/Samples/types';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { LocationPicker } from './LocationPicker';
import { LocationUtils } from './locationUtils';

type Props = React.ComponentProps<typeof LocationPicker> & {
  open: boolean;
  onSubmit: (value: LocationValue) => void;
  onCancel: () => void;
};

type State = {
  value?: LocationValue;
};

export class LocationPickerDialog extends React.Component<Props, State> {
  static getDerivedStateFromProps(nextProps: Props, prevState: State): State {
    return {
      value: nextProps.open ? (prevState.value || nextProps.value) : null,
    };
  }

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { value } = this.state;
    if (value) {
      onSubmit(value);
    }
  };

  handleClose = (event, reason) => {
    if (reason === 'escapeKeyDown') {
      this.props.onCancel();
    }
  };

  render() {
    const { open, value: originalValue, locations, fetchBoxContents, onCancel } = this.props;
    const { value } = this.state;

    const selectedNode = value?.id ? LocationUtils.findNodeById(locations, value.id) : null;
    const okEnabled = (value?.id && value?.position) || (selectedNode?.organized === false);

    let changeLegend = null;
    if (value && originalValue?.id && (value.id !== originalValue.id || value.position !== originalValue.position)) {
      const from = LocationUtils.getLabelForLocation(originalValue, locations);
      const to = LocationUtils.getLabelForLocation(value, locations);
      changeLegend = <table><tbody>
        <tr><td className='text-align-right'>Moving from:</td><td><b>{from}</b></td></tr>
        <tr><td className='text-align-right'>to:</td><td><b>{to}</b></td></tr>
      </tbody></table>;
    }
    return (
      <Dialog
        className='LocationPickerDialog'
        open={open}
        fullWidth
        maxWidth="lg"
        onClose={this.handleClose}
        PaperProps={{
          sx: {
            height: '90vh',
            maxHeight: '90vh',
          },
        }}
      >
        <DialogTitle className='muiDialog-title'>Pick Location</DialogTitle>
        <DialogContent className='contents'>
          <LocationPicker
            value={value}
            locations={locations}
            fetchBoxContents={fetchBoxContents}
            onChange={value => {
              this.setState({ value });
            }}
            changeLegend={changeLegend}
          />
        </DialogContent>

        <Box className='full-width'>
          <StandardDialogActions
            cancelProps={{
              onClick: onCancel,
            }}
            defaultButtonProps={{
              disabled: !okEnabled,
              onClick: this.handleSubmit,
              label: 'OK',
            }} />
        </Box>
      </Dialog>
    );
  }
}
