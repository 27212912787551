import React, { Component } from 'react'
import {
  Checkbox,
  Chip,
  ListItemText,
  Select,
  MenuItem,
} from '@mui/material'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import PropTypes from 'prop-types'

export default class MultiSelect extends Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    inputProps: PropTypes.object,
    items: PropTypes.array.isRequired,
    itemProps: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    value: PropTypes.array.isRequired,
  }

  renderValue = selected => {
    const { disabled, items, itemProps, value, onDelete } = this.props
    const className = disabled ? 'chip--disabled' : undefined

    return (
      value.map(id => {
        const { label } = itemProps(items.find(item => item.id === id))

        return (
          <Chip
            key={id}
            className={className}
            label={label}
            onDelete={onDelete ? onDelete(id) : undefined}
          />
        )
      })
    )
  }

  renderIcon = ({ className = '', ...props }) => {
    if (this.props.disabled) { className += ' icon--disabled' }

    return <ArrowDropDown {...props} className={className} />
  }

  renderItems = () => {
    const { items, itemProps, tagged } = this.props

    return (
      items.map(item => {
        const { id, label, value, selected } = itemProps(item)

        return (
          <MenuItem key={id} value={value}>
            {tagged && <Checkbox checked={selected} color="primary" />}
            <ListItemText primary={label} />
          </MenuItem>
        )
      })
    )
  }

  render() {
    const {
      className,
      disabled,
      inputProps,
      onChange,
      value,
    } = this.props

    return (
      <Select
        multiple
        disabled={disabled}
        className={className}
        inputProps={inputProps}
        value={value}
        onChange={onChange}
        renderValue={this.renderValue}
        IconComponent={this.renderIcon}
      >
        {this.renderItems()}
      </Select>
    )
  }
}
