import { List } from 'immutable'
import { UploadedFile } from '@/Eln/Entry/models/UploadedFile.js'

export default function FilePlugin() {
  function createFileNode(file, size = null) {
  }

  function insertFile(change, file) {
  }

  function insertFileFromFileData(change, fileData) {
    return insertFile(change, new UploadedFile(fileData))
  }

  function insertFiles(change, files) {
    files.forEach(file => {
      change = insertFile(change, file)
    })

    return change
  }

  function onDrop(event, change) {
  }

  function onDropOrPasteFiles(files, change) {
    return change.call(insertFiles, files)
  }

  // this is called from deferPaste of the ClipboardPlugin if no other on paste handling occurs
  function handleOnPaste(files, type, change) {
    switch (type) {
      case 'files': return onDropOrPasteFiles(files, change)
    }
  }

  const SIZE_ORDER = List([
    'none',
    'small',
    'medium',
    'large',
  ])

  function onSizeToggle(change, node, permitted = List([]), current) {
    const permittedOrder = SIZE_ORDER.filter(size => permitted.includes(size))
    const currentKey = permittedOrder.keyOf(current)
    const size = permittedOrder.get(currentKey + 1) || permittedOrder.first()
    return change.setNodeByKey(node.key, { data: node.data.merge({ size: size }) })
  }

  const changes = {
    insertFile,
    insertFileFromFileData,
    insertFiles,
    onSizeToggle,
  }

  const utils = {
    createFileNode,
    handleOnPaste,
  }

  return {
    utils,
    changes,
    onDrop,
  }
}
