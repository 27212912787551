import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Stack,
  Divider,
} from '@mui/material';
import { ShouldRender } from '@cdd/ui-kit/lib/components/conditionals/ShouldRender/v1';
import {
  DLBioisosterResponse,
  DLError,
  BioisosterFragment,
  BioisosterSettings,
  ReferenceStructure,
} from './BioisosterResultsTypes';
import {
  BioisosterInfoButton,
  BioisosterLaunchVisualizationButton,
  BioisosterSettingsButton,
  BioisostersDownloadButton,
} from './BioisosterResultsControls';
import { BioisosterResults } from './BioisosterResults';
import { PropertyCalculator } from '../Properties/calculator';
import { usePersistentState } from '../persistence';
import { BioisosterInfoNoFragments } from './BioisosterInfoDialog';
import { FragmentSelector } from './FragmentSelector';
import { CenterContent } from '../layout';

const hasExperimentalFeatures = true;

type BioisosterResultsDialogProps = {
  open: boolean;
  fetchDLBioisoster: (
    smiles: string,
    count: number
  ) => Promise<DLBioisosterResponse | DLError>;
  smiles: string;
  onClose?: () => void;
  banExternalLinks: boolean;
  skipExternalLinkWarning: boolean;
};

const BioisosterResultsDialog = (props: BioisosterResultsDialogProps) => {
  const { open, smiles } = props;
  const [bioisosterFragments, setBioisosterFragments] = useState<
    Array<BioisosterFragment>
  >([]);
  const [fragments, setFragments] = useState<Array<string>>();
  const [reference, setReference] = useState<ReferenceStructure>({
    structure: '',
    properties: {},
  });
  const [loading, setLoading] = useState(true);
  const [loadingFailed, setLoadingFailed] = useState(false);
  const [settings, setSettings] = usePersistentState<BioisosterSettings>(
    'deep-learning-bioisosteric-suggestions',
    { properties: [], count: 12, showCollectionInformation: false, experimental: false },
  );
  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === 'Escape') {
      props.onClose();
    }
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    const { fetchDLBioisoster } = props;
    setLoading(true);
    fetchDLBioisoster(smiles, settings.count).then((response) => {
      setLoading(false);
      if ('error' in response || response.status !== 'OK') {
        setLoadingFailed(true);
        return;
      }
      if ('result' in response) {
        const calculator = new PropertyCalculator();
        calculator.is_ready().then(() => {
          setReference({
            structure: smiles,
            properties: calculator.predict(response.result.structure),
          });
          response.result.suggestions.forEach((suggestion) => {
            suggestion.bioisosters.forEach((bioisoster) => {
              bioisoster.properties = calculator.predict(bioisoster.structure);
            });
          });
          setBioisosterFragments(response.result.suggestions);
          setFragments(response.result.suggestions.map((suggestion) => suggestion.fragment));
        });
        return;
      }
      setLoadingFailed(true);
    });
  }, [smiles, open, settings.count]);

  const changeSettings = (settings: BioisosterSettings) => {
    setSettings(settings);
  };

  return (
    <Dialog
      className='BioisosterResultsDialog'
      open={open}
      fullWidth
      maxWidth='xl'
      PaperProps={{ sx: { height: 'calc(100% - 64px)' } }}
      onKeyDown={handleKeyDown}
    >
      <BioisosterResultsDialogTitle
        loading={loading}
        bioisosterFragments={bioisosterFragments}
        reference={reference}
        settings={settings}
        onChangeSettings={changeSettings}
      />
      <DialogContent>
        <BioisosterContent
          bioisosterFragments={bioisosterFragments}
          fragments={fragments}
          smiles={smiles}
          reference={reference}
          settings={settings}
          banExternalLinks={props.banExternalLinks}
          loading={loading}
          loadingFailed={loadingFailed}
          onFragmentSelectionChange={setFragments} />
      </DialogContent>
      <Stack direction='row' justifyContent='space-between' alignItems='end'>
        <Stack
          direction='row'
          justifyContent='flex-start'
          spacing={1}
          divider={<Divider orientation='vertical' flexItem />}
          sx={{ padding: '10px' }}
        >
          <BioisosterFooter
            skipExternalLinkWarning={props.skipExternalLinkWarning}
            banExternalLinks={props.banExternalLinks}
          />
        </Stack>
        <DialogActions className='bottom-modal-row'>
          <Button
            variant='text'
            onClick={props.onClose}
            color='primary'
            aria-label='close dialog'
          >
            OK
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

const BioisosterContent = (props: {
  bioisosterFragments: Array<BioisosterFragment>;
  fragments: Array<string>;
  smiles: string;
  reference: ReferenceStructure;
  settings: BioisosterSettings;
  banExternalLinks: boolean;
  loading: boolean;
  loadingFailed: boolean;
  onFragmentSelectionChange: (fragments: Array<string>) => void;
}) => {
  const {
    bioisosterFragments, fragments, smiles, reference, settings, loading, loadingFailed,
    onFragmentSelectionChange,
  } = props;
  if (loadingFailed) {
    return renderErrorFallback();
  }
  if (loading) {
    return CenterContent(<div>Loading...</div>);
  }
  if (!bioisosterFragments || bioisosterFragments.length == 0) {
    return <BioisosterInfoNoFragments />;
  }
  return (
    <>
      <ShouldRender shouldRender={settings.experimental}>
        <FragmentSelector fragments={fragments} structure={smiles} onChange={onFragmentSelectionChange} bioisosterFragments={bioisosterFragments} />
      </ShouldRender>
      <ShouldRender shouldRender={fragments.length > 0}>
        <BioisosterResults
          bioisosterFragments={bioisosterFragments.filter((bioisosterFragment) => fragments.includes(bioisosterFragment.fragment))}
          structure={smiles}
          reference={reference}
          settings={settings}
          banExternalLinks={props.banExternalLinks}
        />
      </ShouldRender>
    </>
  );
};

const BioisosterFooter = (props: {
  skipExternalLinkWarning: boolean;
  banExternalLinks: boolean;
}) => {
  const footer = [];
  if (!props.skipExternalLinkWarning && !props.banExternalLinks) {
    footer.push(
      <Box style={{ fontWeight: 'light', color: 'grey' }} key='external'>
        Warning: links open an external website
      </Box>,
    );
  }
  return <> {footer} </>;
};

type BioisosterResultsDialogTitleProps = {
  loading: boolean;
  bioisosterFragments: Array<BioisosterFragment>;
  reference: ReferenceStructure;
  settings: BioisosterSettings;
  onChangeSettings: (settings: BioisosterSettings) => void;
};

const BioisosterResultsDialogTitle = (
  props: BioisosterResultsDialogTitleProps,
) => {
  const {
    loading,
    bioisosterFragments,
    reference,
    settings,
    onChangeSettings,
  } = props;
  const disabled = loading || !bioisosterFragments.length;
  return (
    <DialogTitle className='muiDialog-title'>
      <Box display='flex' justifyContent='space-between'>
        <Stack
          direction='row'
          spacing={2}
          divider={<Divider orientation='vertical' flexItem />}
        >
          <Stack direction='row' spacing={2} alignItems='center'>
            <span>{'Bioisosteric suggestions'}</span>
          </Stack>
          <Stack direction='row' spacing={4}>
            <BioisosterLaunchVisualizationButton
              disabled={disabled}
              reference={reference}
              bioisosterFragments={bioisosterFragments}
              displayProperties={settings.properties}
              bioisosterSettings={settings}
            />
            <BioisostersDownloadButton
              disabled={disabled}
              reference={reference}
              bioisosterFragments={bioisosterFragments}
              displayProperties={settings.properties}
              bioisosterSettings={settings}
            />
            <BioisosterSettingsButton
              settings={settings}
              disabled={disabled}
              onChange={onChangeSettings}
              hasExperimentalFeatures={hasExperimentalFeatures}
            />
          </Stack>
        </Stack>
        <BioisosterInfoButton />
      </Box>
    </DialogTitle>
  );
};

const renderErrorFallback = () => {
  let message = 'Cannot retrieve bioisostere suggestions.';
  if (window.location.href.includes('localhost')) {
    message +=
      ' Start the deep learning similarity server on your development machine' +
      ' using &quot;make dlsimilarity&quot; and stop it using &quot;make dlsimilarity_stop&quot;';
  }
  return (
    <Box display='flex' height='100%'>
      <Box
        className='error-boundary-msg'
        m='auto'
        paddingLeft='200px'
        paddingRight='200px'
        dangerouslySetInnerHTML={{ __html: message }}
      />
    </Box>
  );
};

export { BioisosterResultsDialog, BioisosterResultsDialogProps };
