import React, { memo, useCallback, useState } from 'react';
import {
  BioisosterFragment,
  BioisosterSettings,
  ReferenceStructure,
} from './BioisosterResultsTypes';
import { DownloadButton, SettingsButton, InfoButton, LaunchVisualizationButton } from '../controls';
import { bioisosterToBlob, downloadBioisosters } from './BioisosterResultsUtilities';
import { BioisosterInfoDialog } from './BioisosterInfoDialog';
import { BioisosterSettingsDialog } from './BioisosterSettingsDialog';

export const BioisostersDownloadButton = (props: {
  bioisosterFragments: Array<BioisosterFragment>;
  reference: ReferenceStructure;
  displayProperties: string[];
  disabled: boolean;
  bioisosterSettings: BioisosterSettings;
}) => {
  const handleClick = () => {
    downloadBioisosters(props);
  };
  return (
    <DownloadButton
      disabled={props.disabled}
      onClick={handleClick}
      tooltip='Download bioisostere results'
    />
  );
};

export const BioisosterSettingsButton = (props: {
  settings: BioisosterSettings;
  onChange: (settings: BioisosterSettings) => void;
  disabled: boolean;
  hasExperimentalFeatures: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (settings: BioisosterSettings) => {
    setOpen(false);
    props.onChange(settings);
  };
  return (
    <>
      <SettingsButton
        onClick={handleClick}
        tooltip='Bioisostere settings'
        disabled={props.disabled}
      />
      {open && (
        <BioisosterSettingsDialog
          settings={props.settings}
          open={open}
          onClose={handleClose}
          hasExperimentalFeatures={props.hasExperimentalFeatures}
        />
      )}
    </>
  );
};

export const BioisosterInfoButton = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <InfoButton onClick={() => setOpen(true)} tooltip='Information' />
      {open && (
        <BioisosterInfoDialog open={open} onClose={() => setOpen(false)} />
      )}
    </>
  );
};

export const BioisosterLaunchVisualizationButton = memo(
  ({
    reference,
    bioisosterFragments,
    displayProperties,
    disabled,
    bioisosterSettings,
  }: {
    bioisosterFragments: Array<BioisosterFragment>;
    reference: ReferenceStructure;
    displayProperties: string[];
    disabled: boolean;
    bioisosterSettings: BioisosterSettings;
  }) => {
    const getDataBlob = useCallback(() => {
      return bioisosterToBlob({
        reference,
        bioisosterFragments,
        displayProperties,
        bioisosterSettings,
      });
    }, [reference, bioisosterFragments, displayProperties]);

    return (
      <LaunchVisualizationButton
        getDataBlob={getDataBlob}
        filename={'bioisoster.csv'}
        disabled={disabled}
      />
    );
  },
);
BioisosterLaunchVisualizationButton.displayName = 'BioisosterLaunchVisualizationButton';
