import React from 'react'
import autobind from 'class-autobind'
import {
  IconButton,
  Tooltip,
} from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

import ExpandMore from '@mui/icons-material/ExpandMore'
import FilterList from '@mui/icons-material/FilterList'

import SubstructureInput from './SubstructureInput'
import FilterFields from '../FilterFields'
import SearchButton from './SearchButton.jsx'
import { searchField as styles } from '../styles.js'
import { term } from '@/shared/utils/stringUtils'

export default class SearchField extends React.Component {
  constructor(props) {
    super(props)
    autobind(this)
  }

  onSubmit(event) {
    event.preventDefault()
    const { submitQuery, handleFilterPanelClose } = this.props

    handleFilterPanelClose()
    submitQuery()
  }

  onQueryTextChange(event) {
    const { updateQuery } = this.props
    const { value } = event.currentTarget

    updateQuery({ text: value })
  }

  renderFilterIcon = () => {
    const { filterPanelVisible } = this.props

    return (
      <IconButton
        className="search-bar__filter"
        style={styles.filterButtonStyle}
        onClick={this.props.handleFilterPanelToggle}
      >
        {filterPanelVisible ? <ExpandMore /> : <FilterList />}
      </IconButton>
    )
  }

  renderFilterPanel = () => {
    return (

      <div>
        <FilterFields />
      </div>
    )
  }

  render() {
    const { text, filterPanelVisible, structuresVisible } = this.props

    return (
      <form className="search-bar__form" onSubmit={this.onSubmit}>
        <Accordion expanded={filterPanelVisible}>
          <AccordionSummary className="search-bar__summary">
            <div className="search-bar__container">
              {
                filterPanelVisible ? this.renderFilterIcon() : (
                  <Tooltip title={`Filter ${term('entry.other', true)}`}>
                    {this.renderFilterIcon()}
                  </Tooltip>
                )
              }
              <input
                className="search-bar__input"
                value={text || ''}
                onChange={this.onQueryTextChange}
                placeholder="Search (e.g. keyword*)"
              />
              {structuresVisible && <SubstructureInput />}
              <SearchButton />
            </div>
          </AccordionSummary>

          <AccordionDetails className="search-bar__filters">
            {this.renderFilterPanel()}
          </AccordionDetails>
        </Accordion>

      </form>
    )
  }
}
