import { LocationNode } from '@/Samples/types';
import { TreeItem } from '@mui/lab';
import { observer } from 'mobx-react';
import React from 'react';
import { LocationUtils } from './locationUtils';
import GridOnIcon from '@mui/icons-material/GridOn';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

type Props = {
  node: LocationNode;
  expanded: Array<string>;
  onSelect?: (node: LocationNode) => void;
  renderIcons?: (node: LocationNode) => React.ReactNode;
};

@observer
export class LocationPickerTreeItem extends React.Component<Props> {
  render() {
    const { node, renderIcons } = this.props;
    const childCounts = LocationUtils.countChildren(node);

    if (!childCounts.total) {
      return null;
    }
    let icon: React.ReactNode;
    if (LocationUtils.isNodeBox(node)) {
      icon = node.organized ? <GridOnIcon /> : <CheckBoxOutlineBlankIcon />;
    }

    const label = node.value + (childCounts.total ? ` (${childCounts.used}/${childCounts.total})` : '');

    return <TreeItem
      className='location-picker-tree-item'
      classes={{
        content: 'location-picker-tree-item-content',
        label: 'location-picker-tree-item-label',
        selected: 'location-picker-tree-item-selected',
        focused: 'location-picker-tree-item-focused',
      }}
      sx={{ padding: 0 }}
      nodeId={'' + node.id}
      label={
        <>
          <span className='location-box-icon'>
            {icon}
          </span>

          <span>{label}</span>
          {renderIcons && <span className='float-right'>{renderIcons(node)}</span>}
        </>
      }>
      {(node.children ?? []).map(child =>
        <LocationPickerTreeItem {...this.props} key={child.id} node={child} />,
      )}
    </TreeItem>;
  }
}
