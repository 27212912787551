import React from 'react';
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  FormControlLabel,
  Stack,
  TextField,
} from '@mui/material';
import { runInAction } from 'mobx';
import { StandardDialogActions } from '@/components/Buttons/DialogButtons';
import { FieldDefinition } from '../types';
import { A } from '@/shared/components/sanitizedTags.js';

type Props = {
  editingRow: FieldDefinition | null;
  onClose();
};

export const ConfigureSampleIDDialog: React.FC<Props> = (props: Props) => {
  const { editingRow, onClose } = props;
  const [enabled, setEnabled] = React.useState(!!editingRow?._enable_sample_identifier_system?.identifier);
  const [prefix, setPrefix] = React.useState(editingRow?._enable_sample_identifier_system?.prefix ?? '');
  const [id, setId] = React.useState(editingRow?._enable_sample_identifier_system?.identifier ?? 1);

  // Use useEffect to reset state when editingRow changes
  React.useEffect(() => {
    if (editingRow) {
      setEnabled(!!editingRow._enable_sample_identifier_system?.identifier);
      setPrefix(editingRow._enable_sample_identifier_system?.prefix ?? '');
      setId(editingRow._enable_sample_identifier_system?.identifier ?? 1);
    }
  }, [editingRow]);

  const onSubmit = () => {
    const { editingRow, onClose } = props;

    runInAction(() => {
      if (enabled) {
        Object.assign(editingRow, {
          _enable_sample_identifier_system: {
            identifier: id,
            prefix,
          },
          unique: true,
          unique_value: true,
        });
      } else {
        delete editingRow._enable_sample_identifier_system;
      }
    });
    onClose();
  };

  return (
    <Dialog
      open={!!editingRow}
    >
      <DialogTitle>Autogenerate Sample Identifiers</DialogTitle>
      <DialogContent>
        <Stack spacing={2} dir='column'>
          <DialogContentText>
            Automatically assign a unique sample identifier to all samples within a Vault.  You must specify a prefix and a starting
            value. Once enabled, please contact <A href='mailto:support@collaborativedrug.com'>CDD support</A> to disable this feature.
          </DialogContentText>

          <Box>
            <FormControlLabel control={
              <Checkbox
                checked={enabled}
                onChange={e => setEnabled(e.currentTarget.checked)} />
            }
              label="Enable Sample ID Automatic Generation"
            />

            <Fade in={enabled}>
              <Box m={2}>
                <TextField
                  required
                  id="input-id-prefix"
                  label="Prefix"
                  variant="outlined"
                  value={prefix}
                  onChange={e => setPrefix(e.currentTarget.value)}
                  sx={{ marginLeft: 2, marginRight: 2 }}
                />
                <TextField
                  required
                  id="input-id-starting-value"
                  label="Starting Value"
                  variant="outlined"
                  type='number'
                  value={id}
                  onChange={e => setId(parseInt(e.currentTarget.value))}
                  sx={{ width: '8rem' }}
                />
              </Box>
            </Fade>
          </Box>
        </Stack>

      </DialogContent>

      <StandardDialogActions
        cancelProps={
          {
            id: 'button_no',
            onClick: onClose,
          }
        }
        defaultButtonProps={{
          id: 'button_yes',
          disabled: !prefix.length || isNaN(id),
          onClick: onSubmit,
          label: 'OK',
        }}
      />
    </Dialog>
  );
};
