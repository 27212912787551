import { LocationNode, LocationValue, Sample } from '@/Samples/types';
import { Card, CircularProgress, Fade } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { LocationBoxPosition } from './LocationBoxPosition';
import { observer } from 'mobx-react';
import { useResizeDetector } from 'react-resize-detector';
import { LocationUtils } from './locationUtils';

type Props = {
  box: LocationNode;
  width: number;
  height: number
  value?: LocationValue;
  loading?: boolean;
  samples?: Array<Sample>;
  onChange?: (value: LocationValue) => void;
};

// gets actual width and height of the container and passes it into the fixed size component
export const LocationBoxPicker = (props: Omit<Props, 'width' | 'height'>) => {
  const { width, height, ref } = useResizeDetector({
    handleHeight: true,
    handleWidth: true,
    refreshMode: 'debounce',
    refreshRate: 10,
  });

  return <div className='LocationBoxPickerContainer' ref={ref}>
    {width && height && <LocationBoxPickerFixedSize
      {...props}
      width={width - 10}
      height={height - 10}
    />}
  </div>;
};

@observer
class LocationBoxPickerFixedSize extends React.Component<Props> {
  handleClickElement = (position: number) => {
    this.props.onChange?.({
      id: this.props.box.id,
      position,
      value: '',
    });
  };

  render() {
    const { value, box, width, height, loading, samples } = this.props;
    if (!this.props.box || LocationUtils.isNodeLocation(this.props.box)) {
      return null;
    }

    const num_columns = box.organized ? box.num_columns : 1;
    const num_rows = box.organized ? box.num_rows : 1;

    const maxDimensions = Math.max(num_columns, num_rows);
    const cellPadding = Math.min(5, Math.max(2, 30 / maxDimensions));

    const boxMargin = 0;

    const rowColLabelSize = 25;
    let positionsWidth = width - rowColLabelSize - cellPadding * 2;
    let positionsHeight = height - rowColLabelSize - cellPadding * 2;

    const cellWidth = Math.max((positionsWidth - boxMargin * 2) / num_columns, 30);
    const cellHeight = Math.max((positionsHeight - boxMargin * 2) / num_rows, 22);
    const cellSizeWithPadding = Math.min(cellWidth, cellHeight);
    positionsWidth = cellSizeWithPadding * num_columns;
    positionsHeight = cellSizeWithPadding * num_rows;

    const cellSize = cellSizeWithPadding - cellPadding * 2 - 2;
    const cardWidth = rowColLabelSize + cellSizeWithPadding * num_columns + boxMargin * 2 + cellPadding * 2;
    const cardHeight = rowColLabelSize + cellSizeWithPadding * num_rows + boxMargin * 2 + cellPadding * 2;

    const cardLeft = Math.max(0, (width - cardWidth) / 2);
    const cardTop = Math.max(0, (height - cardHeight) / 2);

    return <Box className='LocationBoxPicker'>
      <Card raised={true} style={{
        position: 'relative',
        width: cardWidth,
        height: cardHeight,
        left: cardLeft,
        top: cardTop,
      }}>

        {box.organized
          ? <>
            {/* Render column labels */}
            <div className='col-labels' style={{ marginLeft: cellPadding + 2 }}>
              {new Array(num_columns).fill(null).map((v, col) =>
                <label key={col} style={{ width: cellSizeWithPadding }}>{col + 1}</label>,
              )}
            </div>

            {/* Render row labels */}
            <div className='row-labels' style={{ marginTop: rowColLabelSize, width: rowColLabelSize }}>
              {new Array(num_rows).fill(null).map((v, col) =>
                <div key={col} style={{ height: cellSizeWithPadding }}>
                  <label style={{ width: rowColLabelSize }}>{String.fromCharCode(65 + col)}</label>
                </div>,
              )}
            </div>

            <div className='positions'
              style={{ width: positionsWidth, height: positionsHeight, marginTop: rowColLabelSize, marginLeft: rowColLabelSize }}>
              {new Array(num_rows).fill(null).map((v, row) =>
                <div key={row} className='row' style={{ height: cellSizeWithPadding }}>
                  {new Array(num_columns).fill(null).map((v, col) => {
                    const position = row * num_columns + col + 1;
                    const selected = (value?.id === box.id && value.position === position);
                    const sample = (samples ?? []).find(s => s.location.position === position);
                    return <LocationBoxPosition
                      key={col}
                      position={position}
                      selected={selected}
                      sample={sample}
                      cellSize={cellSize} cellPadding={cellPadding}
                      onClick={this.handleClickElement} />;
                  })}

                </div>,
              )}
            </div>
            <Fade in={loading} appear={false} timeout={500}>
              <div className='loading-mask'>
                <CircularProgress className='loading-spinner' />
              </div>
            </Fade>
          </>
          : null}
      </Card>
    </Box>;
  }
}
