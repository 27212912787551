import { PropsOf } from '@emotion/react';
import { Tooltip } from '@mui/material';
import React from 'react';

interface Props extends Partial<PropsOf<typeof Tooltip>> {
  title: React.ReactNode;
  offsetX?: number;
  offsetY?: number;
}
export const ChildWithTooltip = (props: Props) => {
  let offsetProps = {};
  if (props.offsetX !== undefined || props.offsetY !== undefined) {
    offsetProps = {
      slotProps: {
        'popper':
        {
          'modifiers':
            [{
              'name': 'offset',
              'options': {
                'offset': [props.offsetX ?? 0, props.offsetY ?? 0],
              },
            }],
        },
      },
    };
  }

  return <Tooltip {...offsetProps} {...props} placement='bottom'>
    <span>{props.children}</span>
  </Tooltip>;
};
